<template>
  <form id="frm_action">
  <div class="p-formgrid">
    <div class="p-card-field">
      
      <div class="p-field p-grid">
        <label class="p-col-fixed in-d cus-label-title">Tên bộ thẻ <span style="color:red">*</span></label>
      </div>
      <div class="p-field p-grid">
        <div class="p-col">
          <InputText v-model="name" id="name_add" type="text" class="p-col-width" placeholder="Nhập tên bộ thẻ" />
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed in-d cus-label-title">Loại bộ thẻ</label>
      </div>
      <div class="p-field p-grid">
        <div class="p-col">
          <Dropdown id="status_id" v-model="selType" :options="list_type" optionLabel="label" class="p-col-width"/>
        </div>
      </div>
      <div v-if="this.mode=='add'" class="p-field p-grid">
        <label class="p-col-fixed cus-label-title">Thay thế cho bộ thẻ (nếu có)</label>
      </div>
      <div v-if="this.mode=='add'" class="p-field p-grid">
        <div class="p-col vd-select">
          <v-select id= "a1" :filterable="false" :clearable="true" placeholder="Chọn bộ thẻ muốn thay thế" v-model="selDecks" :options="listDecks" label="name" @search="fetchOptionsDecks" class="p-col-width">
            <span slot="no-options" @click="$refs.select.open = false">
              Không có dữ liệu
            </span>
          </v-select>
          <input class="input_tmp_validator" id="validator_list_deck">
        </div>
      </div>
      <div v-if="this.mode=='add'" class="p-field p-grid">
        <label class="p-col-fixed in-d cus-label-title">File apkg <span style="color:red">*</span></label>
      </div>
      <div v-if="this.mode=='add'" class="p-field p-grid">
        <div class="p-col">
          <input class="input_tmp_validator" id="file_add">
          <input class="p-inputtext p-component text-box p-col-width" style="background-color: #EEEEEE;" type="file" @change="onFileSelectApkg" accept=".apkg"/>
          <div v-if="uploadProgress > 0">
            <div class="progress-bar">
              <div class="progress-bar-fill" :style="{ width: uploadProgress + '%' }">
              </div>
              <div  v-if="uploadProgress < 100" class="progress-bar-text">
                Đang upload {{ uploadProgress.toFixed(2) }}%
              </div>
              <div v-else class="progress-bar-text">
                Upload thành công
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-fixed in-d cus-label-title">Ảnh Active</label>
      </div>
      <div class="p-field p-grid">
        <div class="p-col">
          <input class="p-inputtext p-component text-box p-col-width" style="background-color: #EEEEEE;" accept="image/*" name="images[]" type="file" @change="onFileSelect"/>
          <input class="input_tmp_validator" id="images">
          <div class="box-images mt-3 text-box"  v-if="images && images.length">
            <div class="item-img" v-for="(item, index) in images" :key="index" >
              <!-- <div class="button-clear" @click="onFileClear(index)"><img src="../../../assets/ic_clear.svg"/></div> -->
              <img :src="item.objectURL" width="200" height="200"/>
            </div>
          </div>
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-fixed in-d cus-label-title">Ảnh Inactive</label>
      </div>
      <div class="p-field p-grid">
        <div class="p-col">
          <input class="p-inputtext p-component text-box p-col-width" style="background-color: #EEEEEE;" accept="image/*" name="unimages[]" type="file" @change="onFileSelectUn"/>
          <input class="input_tmp_validator" id="unimages">
          <div class="box-images mt-3 text-box"  v-if="unimages && unimages.length">
            <div class="item-img" v-for="(item, index) in unimages" :key="index" >
              <!-- <div class="button-clear" @click="onFileClear(index)"><img src="../../../assets/ic_clear.svg"/></div> -->
              <img :src="item.objectURL" width="200" height="200"/>
            </div>
          </div>
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed cus-label-title">Thứ tự hiển thị <span style="color:red">*</span></label>
      </div>
      <div class="p-field p-grid">
        <div class="p-col">
          <InputNumber v-model="ordinal" id="ordinal_add" class="input-text-box p-col-width" placeholder="Nhập thứ tự"/>
        </div>
      </div>
    </div>
    
    <Toolbar class="fixed-bottom-toolbar">
      <template slot="right">
        <Button
          :label="$constants.TEXT_BUTTON.BACK"
          @click="backToList()"
          class="p-button-warning"
          style="width: 120px !important;"
        />
        <Button
          v-if="mode == 'edit'"
          :label="$constants.TEXT_BUTTON.EDIT"
          @click="saveData()"
          
          style="width: 120px !important;"

        />
        <Button
          v-else
          :label="$constants.TEXT_BUTTON.ADD"
          @click="saveData()"
          
          style="width: 120px !important;"
        />
      </template>
    </Toolbar>

  </div>
  </form>
</template>

<script>
import moment from "moment-timezone";
import DataServices from '@/core/DataServices'
// import gql from "graphql-tag";
import ApiRepository from "@/core/ApiRepository";

export default {
  props: {
    mode: String,
    model: Object,
    users: Array,
    id: String,
  },
  data() {
    return {
      name: '',
      validationErrors: {},
      formMode: this.mode,
      list_type : [
        { value: 1, label: 'Miễn phí'},
        { value: 2, label: 'KOL'},
        { value: 3, label: 'Trả phí'},
      ],
      selType : { value: 1, label: 'Miễn phí'},
      images : [],
      imagesDeletes : [],
      unimages : [],
      time_zone_local : moment.tz.guess(true),
      uploadProgress: 0,
      url_file : '',
      listDecks : [],
      listDecksAll : [],
      selDecks : null,
      ordinal : 1,
    }
  },
  async created() {
    await this.getListDecks()
  },
  async mounted() {
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("this.modelthis.model:this.model",this.model)
      this.name = this.model.name;
      if(this.model.type == 1){
        this.selType = { value: 1, label: 'Miễn phí'}
      }else if(this.model.type == 2){
        this.selType = { value: 2, label: 'KOL'}
      }else if(this.model.type == 3){
        this.selType = { value: 3, label: 'Trả phí'}
      }
      this.ordinal = this.model.ordinal;
      if (!this.$commonFuction.isEmpty(this.model.relationship_file)) {
        if(this.model.relationship_file.length > 0){
          for (let i = 0; i < this.model.relationship_file.length; i++) {
            const e = this.model.relationship_file[i];
            if(e.type == 'ACTIVE_DECKS'){
              let url = e.file.url
              let split = url.split('/')
              let fileName = split[split.length - 1]
              let obj = {'name': fileName, 'file': '', 'objectURL': url,type:'old_edit'}//type:'old_edit' nếu gặp type này ko update avatar
              this.images.push(obj)
            }else if(e.type == 'UNACTIVE_DECKS'){
              let url = e.file.url
              let split = url.split('/')
              let fileName = split[split.length - 1]
              let obj = {'name': fileName, 'file': '', 'objectURL': url,type:'old_edit'}//type:'old_edit' nếu gặp type này ko update avatar
              this.unimages.push(obj)
            }
          }
        }
      }
      
    }
    
  },
  methods: {
    async fetchOptionsDecks(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        let {data} = await this.$apollo.query({
          query: DataServices.getList('decks',{"fields" : "id name"}),
          variables: {
            where_key: {
              deleted: {_eq: false},
              is_tmp: {_eq: false},
              plain_name:{_like: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
            },
            orderBy:{created_at: 'desc'}
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        this.listDecks = list
      }else{
        this.listDecks = this.listDecksAll
      }
    },
    async getListDecks(){
      let {data} = await this.$apollo.query({
        query: DataServices.getList('decks',{"fields" : "id name"}),
        variables: {
          where_key: {
            deleted: {_eq: false},
            is_tmp: {_eq: false},
          },
          orderBy:{created_at: 'desc'}
        },
        fetchPolicy: "network-only"
      })

      var list = data[Object.keys(data)];
      // var list_courses = [];
      this.listDecks = list
      this.listDecksAll = list
      // this.selCourses = null;
    },
    backToList() {
      this.$emit('back');
    },

    async validateData() {
      
      let name_add = document.getElementById("name_add");
      if (this.name == undefined || this.name == null || this.name == ''){
        name_add.setCustomValidity("Vui lòng nhập tên bộ thẻ!");
      }else {
        name_add.setCustomValidity("");
      }
      if(this.mode == 'add'){
        let file_add = document.getElementById("file_add");
        if (this.url_file == undefined || this.url_file == null || this.url_file == ''){
          file_add.setCustomValidity("Vui lòng chọn file!");
        }else {
          file_add.setCustomValidity("");
        }
      }

      let ordinal_add = document.getElementById("ordinal_add");
      
      if(this.ordinal == 0){
        ordinal_add.setCustomValidity("Vui lòng nhập thứ tự lớn hơn 0!");
      }else{
        if (this.ordinal == undefined || this.ordinal == null || this.ordinal == ''){
          ordinal_add.setCustomValidity("Vui lòng nhập thứ tự!");
        }else {
          if(this.value >= 1000000000){
            ordinal_add.setCustomValidity("Vui lòng nhập tháng không quá 1.000.000.000!");
          }else{
            ordinal_add.setCustomValidity("");
          }
        }
      }
      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        inpObj.reportValidity();
        return true;
      }

    },
    validateEmail() {
      var re = /\S+@\S+\.\S+/;
      return re.test(this.email);
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
       // this.saving = false;
        this.$commonFuction.is_loading(false);
        return
      }
      var params = {
        name : this.name,
        type : this.selType,
        images : this.images,
        unimages : this.unimages,
        imagesDeletes : this.imagesDeletes,
        url_file : this.url_file,
        selDecks : this.selDecks,//bộ thẻ muốn thay thế
        ordinal : this.ordinal,
      }
      console.log("params", params)
      // this.$commonFuction.is_loading(false);
      this.$emit('save', params);
    
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    async onFileSelectApkg(event) {
      const file = event.target.files[0];
      let acceptTags = ["apkg","APKG"];
      if (!acceptTags.includes(file.name.split(".").pop())) {
        alert(
          "Vui lòng file có định dạng là: apkg!"
        );
        event.target.value = "";
        return;
      }
        
      const chunkSize = 1024 * 1024 * 5; //mỗi lần upload pack là 5MB
      const totalChunks = Math.ceil(file.size / chunkSize);
      const fileId = Date.now()+''; // Tạo ID duy nhất cho file
      let start = 0;
      let uploadedChunks = 0;
      this.uploadProgress = 0;

      for (let i = 0; i < totalChunks; i++) {
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);
        const formData = new FormData();
        formData.append('file', chunk, file.name);
        formData.append('chunk', i);
        formData.append('totalChunks', totalChunks);
        formData.append('fileId', fileId);
        formData.append('fileName', file.name);
        try {
          var data = await this.uploadChunk(formData);
          uploadedChunks += 1;
          this.uploadProgress = (uploadedChunks / totalChunks) * 100;
          if(data.status == "DONE"){
            this.url_file = data.url_file
          }
        } catch (error) {
          console.error('Upload failed:', error);
          event.target.value = "";
          this.url_file = '';
          this.uploadProgress = 0;
          break; // Handle error
        }
        start = end;
      }
      console.log("this.url_file",this.url_file)
    },

    async uploadChunk(formData) {
      return await ApiRepository.post('/_api/upload-chunk/upload_muti', formData, {}).then(function (response) {
        var dataUpload = response.data.data;
        return dataUpload;
      })
    },
    async onFileSelect(event) {
      let files = event.target.files || event.dataTransfer.files;
      if (files.length > 0) {
        if (files.length > 1) {
          alert("Chỉ được upload tối đa 1 ảnh");
          return;
        }
        let acceptTags = ["jpeg","jpg","gif","png","JPEG","JPG","PNG","GIF",];
        for (let i = 0; i < files.length; i++) {
          if (!acceptTags.includes(files[i].name.split(".").pop())) {
            alert(
              "Vui lòng upload ảnh có định dạng là: jpeg, jpg, gif, png!"
            );
            return;
          }
        }
        var arrTemp = []
        for (let file of files) {
          // if (file.size <= this.maxFileSize) {
            file.source = file;
            file.objectURL = window.URL.createObjectURL(file);
            arrTemp.push(file);
          // }
        }
        
        // if (this.images.length > 0 && this.images[0].file === "") {
        //   this.imagesDeletes.push(this.images[0]);
        // }
        this.images = [];
        this.images = this.images.concat.apply(this.images, arrTemp);
      }
      event.target.value = "";
      // console.log("this.images",this.images)
      return;
    },
    async onFileSelectUn(event) {
      let files = event.target.files || event.dataTransfer.files;
      if (files.length > 0) {
        if (files.length > 1) {
          alert("Chỉ được upload tối đa 1 ảnh");
          return;
        }
        let acceptTags = ["jpeg","jpg","gif","png","JPEG","JPG","PNG","GIF",];
        for (let i = 0; i < files.length; i++) {
          if (!acceptTags.includes(files[i].name.split(".").pop())) {
            alert(
              "Vui lòng upload ảnh có định dạng là: jpeg, jpg, gif, png!"
            );
            return;
          }
        }
        var arrTemp = []
        for (let file of files) {
          // if (file.size <= this.maxFileSize) {
            file.source = file;
            file.objectURL = window.URL.createObjectURL(file);
            arrTemp.push(file);
          // }
        }
        
        // if (this.unimages.length > 0 && this.unimages[0].file === "") {
        //   this.imagesDeletes.push(this.images[0]);
        // }
        this.unimages = [];
        this.unimages = this.unimages.concat.apply(this.unimages, arrTemp);
      }
      event.target.value = "";
      // console.log("this.images",this.images)
      return;
    },
  }

}
</script>

<style lang="scss">
  .p-multiselect {
    width: 450px;
  }
  .p-multiselect-label:not(.p-placeholder) {
    padding-top: .25rem;
    padding-bottom: .25rem;
    
  }
  .category-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .2rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }
  .p-multiselect-label-container{
    height: 40px;
  }
  .p-password .pi.pi-eye {
    color: #000;
    font-size: 14px;
  }
  .progress-bar {
    width: 100%;
    background-color: #af9d9d;
    border: 1px solid #af9d9d;
    height: 30px;
    margin-top: 10px;
    position: relative; /* Đặt vị trí của progress-bar để chứa thanh tiến trình con */
  }

  .progress-bar-fill {
    height: 100%;
    background-color: #4caf50;
    width: 0%; /* Will be updated dynamically */
    transition: width 0.4s ease;
  }

  .progress-bar-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
    pointer-events: none; /* Đảm bảo văn bản không bị ảnh hưởng bởi các sự kiện chuột */
  }

</style>
