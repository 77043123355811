<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <formEdit
          :key="componentKey"
          :mode="mode"
          :id="id"
          @back="backToList"
          @save="saveData"
          :model="models"/>

        <Dialog
          :visible.sync="showLoadDialog"
          :contentStyle="{overflow: 'visible'}"
          :modal="true"
          class="hide-css"
          
        >
          <strong>{{ upload_progress.toFixed(0) }}%</strong>
        </Dialog>  
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DataServices from '@/core/DataServices'
import formEdit from './add'
import ApiRepository from "@/core/ApiRepository";
// import moment from "moment-timezone";
export default {
  components: {
    formEdit
  },
  data() {
    return {
      validationErrors: [],
      title: "Tạo bộ thẻ",
      mode: 'add',
      models: {},
      images: [],
      oldImages: [],
      componentKey: 0,
      page_transaction:null,
      id:null,
      showLoadDialog : false,
      upload_progress : 0,
    }
  },
  async mounted () {
    // console.log("this.$route.name:",this.$route.query.page_transaction);
    this.mode = this.$route.name === 'editDecks' ? 'edit' : this.mode;
    console.log("this.mode",this.mode)
    this.title = this.mode === 'edit' ? 'Chỉnh sửa bộ thẻ' : this.title;
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_DECKS);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    backToList() {
      this.$router.push({ path: '/admin/list-decks' });
    },
    convertPhone(numberPhone) {
      var phone = numberPhone
      if (numberPhone[0] === '0') {
        phone = phone.substring(1);
      }
      phone = phone.replace(' ', '')
      phone = '+84' + phone
      return phone
    },
    async saveData(params) {
      if (params !== undefined && Object.entries(params).length > 0) {
        var objects = {
          'type': params.type.value,
          'name': params.name,
          'plain_name': this.$commonFuction.SkipVN(params.name),
          'ordinal' : params.ordinal
        }
        var that = this;
        if (this.mode === 'add') {
          objects.is_tmp = true;
          objects.count_media = 0;
          var decks_id = null
          if(params.selDecks && params.selDecks.id){
            decks_id = params.selDecks.id
          }
          objects.decks_id = decks_id
          var dataInsert = this.$commonFuction.convertJsonObject(objects);
            await that.$apollo
              .mutate({
                mutation: DataServices.insertData('decks'),
                variables: {
                  objects: dataInsert
                }
              })
              .then(async (res)  => {
                if (res.data[Object.keys(res.data)].affected_rows) {
                  var returning = res.data[Object.keys(res.data)].returning[0];
                  // console.log("returningreturning:",returning);
                  if (params.images && params.images.length > 0){
                    that.$CoreService.uploadImages(that.$constants.FOLDER_S3.DECKS, params.images).then((resImage) => {
                      console.log("resresres:", resImage);
                      if (resImage) {
                        var objectImage = resImage[0];//lấy 1 tấm hình
                        that.$CoreService.updateDataImage(that.$constants.TYPE_IMAGE.ACTIVE_DECKS, returning.id, objectImage)
                      }
                    });
                  }
                  if (params.unimages && params.unimages.length > 0){
                    that.$CoreService.uploadImages(that.$constants.FOLDER_S3.DECKS, params.unimages).then((resImage) => {
                      console.log("resresres:", resImage);
                      if (resImage) {
                        var objectImage2 = resImage[0];//lấy 1 tấm hình
                        that.$CoreService.updateDataImage(that.$constants.TYPE_IMAGE.UNACTIVE_DECKS, returning.id, objectImage2)
                      }
                    });
                  }
                  that.checkParseApkg(returning.id,params.url_file,decks_id);
                }
              }).catch(async error => {
                console.log('error', error);
                that.$toast.add({
                  severity: 'error',
                  summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                  detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                  life: 3000
                });
                this.$commonFuction.is_loading(false);
                // this.summitEvent = false;
              });
        //EDIT
        } else  {
          console.log("????????????????????",objects)
          var uid = this.$route.params.id
          var queryUpdate = `
            mutation update_decks_multil($decks_object: decks_set_input) {
              update_decks(where: { id: {_eq: "${uid}"}}, _set: $decks_object) {
                affected_rows
                returning {
                  id
                }
              }
              
            }
          `;
          let variables = {
            decks_object: objects,
          };
          that.$apollo.mutate({
            mutation: gql(queryUpdate),
            variables: variables
          }).then(async (res) => {
            console.log(res)
            var returning = {id : this.models.id}
            if (params.images && params.images.length > 0){
              var type_image = params.images[0].type;
              //check hình ảnh có phải hình cũ ko || nếu hình cũ thì ko update
              if (type_image != 'old_edit') {
                //xóa hình ảnh record nếu có sự thay đổi
                that.$CoreService.deleteDataImage(that.$constants.TYPE_IMAGE.ACTIVE_DECKS, returning.id).then((resDelete) => {
                  console.log("resresres:", resDelete);
                  that.$CoreService.uploadImages(that.$constants.FOLDER_S3.DECKS, params.images).then((resImage) => {
                    console.log("resresres:", resImage);
                    if (resImage) {
                      var objectImage = resImage[0];//lấy 1 tấm hình
                      that.$CoreService.updateDataImage(that.$constants.TYPE_IMAGE.ACTIVE_DECKS, returning.id, objectImage).then(() => {
                       
                      });
                    }
                  });
                });
              }
            }
            if (params.unimages && params.unimages.length > 0){
              var type_image2 = params.unimages[0].type;
              //check hình ảnh có phải hình cũ ko || nếu hình cũ thì ko update
              if (type_image2 != 'old_edit') {
                //xóa hình ảnh record nếu có sự thay đổi
                that.$CoreService.deleteDataImage(that.$constants.TYPE_IMAGE.UNACTIVE_DECKS, returning.id).then((resDelete) => {
                  console.log("resresres:", resDelete);
                  that.$CoreService.uploadImages(that.$constants.FOLDER_S3.DECKS, params.unimages).then((resImage) => {
                    console.log("resresres:", resImage);
                    if (resImage) {
                      var objectImage = resImage[0];//lấy 1 tấm hình
                      that.$CoreService.updateDataImage(that.$constants.TYPE_IMAGE.UNACTIVE_DECKS, returning.id, objectImage).then(() => {
                        that.redirectEdit();
                      });
                    }
                  });
                });
              } else {
                that.redirectEdit();
              }
            }else{
              that.redirectEdit();
            }
          })
        }
      }
    },
    async redirectEdit() {
      var that = this;
      that.$commonFuction.is_loading(false);
      var router_back = {path: '/admin/list-decks'};
      that.$router.push(router_back);
      that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('decks', {"fields" : `id name type ordinal relationship_file {id type file {id url}}`}),
        variables: {
          where_key: {
            id: {'_eq': id}
          }
        },
        fetchPolicy: "network-only"
      })
      // console.log("datadata:",data);
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
      }
      this.componentKey++;
    },
    async checkParseApkg(id,url_file,decks_id=null){
      //gọi api giải nén
      var objects = {
        id : id,
        url_file : url_file,
        decks_id : decks_id,
      }
      await ApiRepository.post('/_api/common/parse_apkg', objects, {}).then(function (response) {
        console.log("response=",response)
      })
      this.$commonFuction.is_loading(false);
      this.showLoadDialog = true
      var that = this
      var intervalParseApkg = setInterval(async function () {
        var {data} = await that.$apollo.query({
        query: DataServices.getList('decks', {"fields" : `id is_tmp is_error total_media count_media`}),
          variables: {
            where_key: {
              id: {'_eq': id}
            }
          },
          fetchPolicy: "network-only"
        })
        // console.log("datadata:",data);
        var model = data[Object.keys(data)];
        if (model.length > 0) {
          var deck = model[0]
          if(deck.is_tmp == false && deck.is_error == false){
            //hiện thành công
            clearInterval(intervalParseApkg);
            that.showLoadDialog = false
            that.redirectEdit()
          }else if(deck.is_error == true){
            clearInterval(intervalParseApkg);
            that.showLoadDialog = false
            that.ShowError()
          }else{
            if(deck.count_media > 0){
              that.uploadProgressdata(deck.count_media)
            }
          }
        }
      }, 5000);
    },
    async uploadProgressdata(number){
      this.upload_progress = number ? number*1 : 0
    },
    async ShowError() {
      this.$commonFuction.is_loading(false);
      this.$toast.add({ severity: 'error', summary: 'Thất bại', detail: 'Thêm thất bại. Vui lòng thử lại', life: 3000 })
    },
  },
}
</script>

<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:450px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;}
      }
      .box-images{ margin-top: 10px; position: relative;
        .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
          &.p-col-5{width: 18%;;}
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain;}
          .button-clear{width: 18px; height: 18px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 5px; padding: 0;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
</style>
<style>
.list-group-v {
  /* display: -webkit-box;
   display: -ms-flexbox;*/
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  /*-ms-flex-direction: column;*/
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-v:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}
.list-group-item-v {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125) ;
  height: 50px;
}
.box-check-box{
  position: relative;
}
.box-check-box label{
  position: absolute;
  left: 0px;
  top: 2px;
}
.box-check-box .check-box-day{
  position: absolute;
  right: 0px;
  top: 2px;
}
.p-field {
  margin-bottom: 0.5rem;
}
.hide-css .p-dialog-header{
  display : none;
}
.hide-css .p-dialog-content{
  width:300px;
  height:120px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}
</style>